import { FiltersStateService } from '../../modules/shell--search-page-root/filters/common/filters-state.service';
import { FiltersService } from "./travel-style/filters/filters.service";
import { appWindowObjectPrefix, getWindowRef } from "@hiptraveler/common";

export function getFiltersService(): FiltersService {
  return getWindowRef()[`${appWindowObjectPrefix}_search_filters_service_ref`];;
}

export function getFiltersStateService(): FiltersStateService {
  return getWindowRef()[`${appWindowObjectPrefix}_search_filters_state_service_ref`];;
}
