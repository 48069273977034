import { AfterViewInit, Directive, ElementRef, HostListener, Inject, NgModule } from '@angular/core';
import { filter, firstValueFrom, fromEvent, takeUntil, throttleTime } from 'rxjs';

import { EFAStateServiceService, screens, ScreenView } from '../shared';
import { Environment, ENVIRONMENT, getWindowRef, promiseDelay } from '@hiptraveler/common';

@Directive({
  selector: '[hostScroll]'
})
export class HostScrollDirective implements AfterViewInit {

  currentScreen: ScreenView = ScreenView.welcome;

  constructor(
    @Inject(ENVIRONMENT) private env: Environment,
    private elementRef: ElementRef<HTMLDivElement>,
    public stateService: EFAStateServiceService
  ) { }

  get element(): HTMLDivElement {
    return this.elementRef.nativeElement;
  }

  ngAfterViewInit(): void {

    this.setWelcomeViewDisplay('show');
    this.scrollObserver();

    this.stateService.scrollToView$.pipe(
      takeUntil(this.stateService.subscription$)
    ).subscribe(this.scrollToView.bind(this));

    this.stateService.scrollToScreen$.pipe(
      takeUntil(this.stateService.subscription$)
    ).subscribe(this.scrollTo.bind(this));
  }

  @HostListener('scroll', ['$event'])
  async observeOnScroll(event: any): Promise<void> {
    const isMobile = await firstValueFrom(this.stateService.isMobile$);
    const scrollTop = event.target.scrollTop;
    const value = isMobile ? (getWindowRef().innerHeight - 58) * 1 - 8 : 8;
    this.setWelcomeViewDisplay(scrollTop < value ? 'show' : 'hide');
  }

  async setWelcomeViewDisplay(value: 'show' | 'hide'): Promise<void> {

    await promiseDelay(0)
    const uxTriggerClass = 'view-display';
    const welcomeView = this.element.querySelector('.experience-finder-ai--host-welcome-view');

    if (value === 'show' && !welcomeView?.classList.contains(uxTriggerClass)) {
      welcomeView?.classList.add(uxTriggerClass);
      this.stateService.welcomeViewTypeState$$.next(true);
    }
    
    if (value === 'hide' && welcomeView?.classList.contains(uxTriggerClass)) {
      welcomeView?.classList.remove(uxTriggerClass);
      this.stateService.welcomeViewTypeState$$.next(false);
    }
  }

  scrollObserver(): void {
   if (this.env.local) {
     setTimeout(() => this.scrollTo(this.stateService.view.screen2));
   }

    this.stateService.isMobile$.pipe(
      takeUntil(this.stateService.subscription$)
    ).subscribe((state: boolean) => {
      this.element.style.overflowY = state ? 'auto' : 'hidden'; 
    });

    this.stateService.scrolling$.pipe(
      takeUntil(this.stateService.subscription$)
    ).subscribe((scrolling: boolean) => {
      this.element.style.overflowY = scrolling ? 'hidden' : 'auto'; 
    });

    let startY = 0;

    fromEvent(this.element, 'touchstart').pipe(
      takeUntil(this.stateService.subscription$),
    ).subscribe((event: any) => {
      if (this.stateService.scrolling$$.value) return;
      startY = event.touches[0].clientY;
    });
    
    fromEvent(this.element, 'touchend').pipe(
      takeUntil(this.stateService.subscription$),
    ).subscribe((event: any) => {
      if (this.stateService.scrolling$$.value) return;

      const currentIndex = screens.indexOf(this.currentScreen);
      const endY = event.changedTouches[0].clientY;
      const deltaY = startY - endY;
      
      if (deltaY > 50) {
        const newScreen = screens[currentIndex + 1];
        this.scrollTo(this.stateService.view[newScreen] || 0);
      }
      if (deltaY < -50) {
        const newScreen = screens[currentIndex - 1];
        this.scrollTo(this.stateService.view[newScreen] || 0);
      }
    });

    fromEvent(this.element, 'wheel').pipe(
      throttleTime(350),
      takeUntil(this.stateService.subscription$),
      filter(Boolean)
    ).subscribe((event: any) => {

      if (event.wheelDeltaX !== 0 || this.stateService.inputPending$$.value) return;
      const allowScroll = this.stateService.allowScroll$$.value;
      const scrollDirection = event.wheelDeltaY > 0 ? 'up' : 'down'
      const currentIndex = screens.indexOf(this.currentScreen);

      if (scrollDirection === 'down' && currentIndex !== -1 && currentIndex < screens.length - 1) {
        const newScreen = screens[currentIndex + 1];
        [ 'allow', 'allow-down' ].includes(allowScroll) && this.scrollTo(this.stateService.view[newScreen]);
      }
      if (scrollDirection === 'up' && currentIndex > 0) {
        const newScreen = screens[currentIndex - 1];
        [ 'allow', 'allow-up' ].includes(allowScroll) && this.scrollTo(this.stateService.view[newScreen]);
      }
    });
  }

  scrollTo(value: number): void {

    if (this.stateService.scrolling$$.value) return;

    const valueIndex = Object.values(this.stateService.view).indexOf(value);
    this.currentScreen = Object.keys(this.stateService.view)[valueIndex] as ScreenView;
    this.stateService.scrolling$$.next(true);
    this.stateService.screenViewValue$$.next(this.currentScreen);

    this.element.scrollTo({
      top: value || 0, behavior: 'smooth'
    });

    setTimeout(() => {
      this.element.scrollTop = value;
    }, this.stateService.timeout.scrollTop);
    setTimeout(() => {
      this.stateService.scrolling$$.next(false);
    }, this.stateService.timeout.scrolling);
  }

  scrollToView(value: 'next' | 'previous'): void {
    const currentIndex = screens.indexOf(this.currentScreen) as any;
    if (value === 'next') {
      const screen = `screen${currentIndex + 2}` as ScreenView;
      this.scrollTo(this.stateService.view[screen])
    }
    if (value === 'previous') {
      const screen = `screen${currentIndex - 2}` as ScreenView;
      this.scrollTo(this.stateService.view[screen])
    }
  }

}

@NgModule({
  declarations: [ HostScrollDirective ],
  exports:      [ HostScrollDirective ]
})
export class HostScrollDirectiveModule { }
