import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

import { ExperienceFinderScreenModule, ExperienceFinderMobileModule } from '../experience-finder-screen';

import { ExperienceFinderComponent } from './experience-finder.component';

const MODULES = [
  CommonModule,
  MatDialogModule,
];

const LIBRARIES = [
  ExperienceFinderScreenModule,
  ExperienceFinderMobileModule
];

const COMPONENTS = [
  ExperienceFinderComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ]
})
export class ExperienceFinderModule { }
