import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject, delay, filter, map } from 'rxjs';
import { get } from 'lodash';

import { LocationData } from '@hiptraveler/data-access/api';
import { SearchState } from '@hiptraveler/data-access/search';
import { HT_SEARCH_LOCATION_KEY, SEARCH_ROUTES, currentLang, PlaceResult, getWindowRef, SearchLocationData } from '@hiptraveler/common';

export type SearchRoute = 'thingstodo' | 'foodanddrink' | 'hotels' | 'experiences' | 'community';

export function parsePathSegment(segmentIndex: number): SearchRoute | undefined {
  const pathname = getWindowRef()?.location?.pathname;
  return pathname?.split('/')[segmentIndex];
}

@Injectable({
  providedIn: 'root'
})
export class SearchLocationService {

  currentValue: SearchLocationData | undefined;
  previousValue: SearchLocationData | undefined;

  prevSearchLocation$$ = new BehaviorSubject<SearchLocationData | undefined>(undefined);
  searchLocation$$ = new Subject<SearchLocationData | undefined>();
  searchLocation$ = this.searchLocation$$.asObservable().pipe(
    filter(Boolean),
    filter(x => !!x?.name && x?.name !== 'undefined'),
    delay(0)
  );
  searchLocationState$ = this.searchLocation$$.asObservable().pipe(map(e => !!e));

  constructor(
    private router: Router,
    private store: Store
  ) { }

  get data(): SearchLocationData | null {
    const data = getWindowRef()?.localStorage?.getItem(HT_SEARCH_LOCATION_KEY);
    return data ? JSON.parse(data) : null;
  }

  get rootSearchRoute(): boolean {
    return !getWindowRef()?.location?.pathname?.includes(`/${currentLang()}/`);
  }

  get rootSearchPage(): boolean {
    const pathname = get(getWindowRef(), 'location.pathname', '');
    const basePath = `/${currentLang()}/search`;
    return [ basePath, `${basePath}/` ].some(e => pathname.includes(e));
  }

  get searchRoute(): boolean {
    return SEARCH_ROUTES.some((path: string) => getWindowRef()?.location?.pathname?.startsWith(`/${currentLang()}/${path}`));
  }

  get searchPageRoute(): boolean {
    return this.searchRoute || this.rootSearchPage || this.rootSearchRoute;
  }

  get locationData$(): Observable<LocationData | null> {
    return this.store.select(SearchState.locationData);
  }

  get placename(): string | undefined {
    if (!this.searchRoute) return undefined;
    return parsePathSegment(3) || this.placenameByRouter;
  }

  get searchRoutePath(): SearchRoute | undefined {
    if (!this.searchRoute) return undefined;
    return parsePathSegment(2);
  }

  get placenameByRouter(): string | undefined {
    return this.router.url?.split('/')[3]?.split('#')[0]?.split('?')?.[0];
  }

  searchRouteLocationByUrl(url: string): boolean {
    return SEARCH_ROUTES.some((path: string) => url.startsWith(`/${currentLang()}/${path}/`));
  }
  
  searchRouteByUrl(url: string): boolean {
    return SEARCH_ROUTES.some((path: string) => url.startsWith(`/${currentLang()}/${path}`));
  }

  emitPlaceResultDataToState(result: PlaceResult): void {
    if (!result) return;
    this.updateSearchLocation({
      name: result.name,
      location: result.formatted_address || 'undefined',
      locId: result.place_id || 'undefined',
      latitude: result?.geometry?.location?.lat(),
      longitude: result?.geometry?.location?.lng(),
      type: result?.types?.[0] || 'undefined'
    });
  }

  updateSearchLocation(value: SearchLocationData | undefined): void {
    this.searchLocation$$.next(value);
    this.previousValue = this.currentValue;
    this.currentValue = value;
  }

}
