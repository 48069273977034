import { Directive, ElementRef, ViewChild, ViewContainerRef } from "@angular/core";

import { promiseDelay } from "@hiptraveler/common";
import { environment as env } from "@hiptraveler/environments";

@Directive()
export class ExperienceFinderAi {

  @ViewChild('host') hostContainer: ElementRef<HTMLDivElement>;
  @ViewChild('brandHeader', { read: ViewContainerRef }) brandHeaderContainer: ViewContainerRef;
  @ViewChild('welcomeView', { read: ViewContainerRef }) welcomeViewContainer: ViewContainerRef;
  @ViewChild('carouselGridView', { read: ViewContainerRef }) carouselGridViewContainer: ViewContainerRef;
  @ViewChild('inputControl', { read: ViewContainerRef }) inputControlContainer: ViewContainerRef;
  @ViewChild('mainSelection', { read: ViewContainerRef }) mainSelectionContainer: ViewContainerRef;
  @ViewChild('carouselTravel', { read: ViewContainerRef }) carouselTravelContainer: ViewContainerRef;
  @ViewChild('carouselExperience', { read: ViewContainerRef }) carouselExperienceContainer: ViewContainerRef;
  @ViewChild('carouselActivities', { read: ViewContainerRef }) carouselActivitiesContainer: ViewContainerRef;
  @ViewChild('carouselLocation', { read: ViewContainerRef }) carouselLocationContainer: ViewContainerRef;
  @ViewChild('calendar', { read: ViewContainerRef }) calendarContainer: ViewContainerRef;
  @ViewChild('clientInfo', { read: ViewContainerRef }) clientInfoContainer: ViewContainerRef;
  @ViewChild('summary', { read: ViewContainerRef }) summaryContainer: ViewContainerRef;

  delay: number = 500;

  get host(): HTMLDivElement {
    return this.hostContainer.nativeElement;
  }

  initializeComponents(): void {
    this.brandHeaderView(this.brandHeaderContainer);
    this.setupWelcomeView(this.welcomeViewContainer);
    this.setupCarouselGridView(this.carouselGridViewContainer);
    this.setupInputControl(this.inputControlContainer);
    this.mainSelectionView(this.mainSelectionContainer);
    this.carouselTravelView(this.carouselTravelContainer);
    this.carouselExperienceView(this.carouselExperienceContainer);
    this.carouselActivitiesView(this.carouselActivitiesContainer);
    this.carouselLocationView(this.carouselLocationContainer);
    this.calendarView(this.calendarContainer);
    this.clientInfoView(this.clientInfoContainer);
    this.summaryView(this.summaryContainer);
  }

  private async brandHeaderView(container: ViewContainerRef): Promise<void> {
    try {
      await import('../components/brand-header/brand-header.module');
      const resolver = await import('../components/brand-header/brand-header.component');
      container.createComponent(resolver.BrandHeaderComponent);
    } catch (e) { env.local && console.warn('Error: TemplateInitializer: "brandHeaderView" module not found.', e); }
  }

  private async setupInputControl(container: ViewContainerRef): Promise<void> {
    try {
      await import('../components/input-control/input-control.module');
      const resolver = await import('../components/input-control/input-control.component');
      container.createComponent(resolver.InputControlComponent);
    } catch (e) { env.local && console.warn('Error: TemplateInitializer: "setupInputControl" module not found.', e); }
  }

  private async setupWelcomeView(container: ViewContainerRef): Promise<void> {
    try {
      await import('../screens/1-welcome-view/welcome-view.module');
      const resolver = await import('../screens/1-welcome-view/welcome-view.component');
      container.createComponent(resolver.WelcomeViewComponent);
    } catch (e) { env.local && console.warn('Error: TemplateInitializer: "setupWelcomeView" module not found.', e); }
  }

  private async setupCarouselGridView(container: ViewContainerRef): Promise<void> {
    try {
      await import('../screens/2-m-carousel-grid/carousel-grid.module');
      const resolver = await import('../screens/2-m-carousel-grid/carousel-grid.component');
      container.createComponent(resolver.CarouselGridComponent);
    } catch (e) { env.local && console.warn('Error: TemplateInitializer: "setupCarouselGridView" module not found.', e); }
  }

  private async mainSelectionView(container: ViewContainerRef): Promise<void> {
    try {
      await import('../screens/2-main-selection/main-selection.module');
      const resolver = await import('../screens/2-main-selection/main-selection.component');
      container.createComponent(resolver.MainSelectionComponent);
    } catch (e) { env.local && console.warn('Error: TemplateInitializer: "mainSelectionView" module not found.', e); }
  }

  private async carouselExperienceView(container: ViewContainerRef): Promise<void> {
    try {
      await import('../screens/3-carousel-experiences/carousel-experiences.module');
      const resolver = await import('../screens/3-carousel-experiences/carousel-experiences.component');
      container.createComponent(resolver.CarouselExperiencesComponent);
    } catch (e) { env.local && console.warn('Error: TemplateInitializer: "carouselExperienceView" module not found.', e); }
  }

  private async carouselTravelView(container: ViewContainerRef): Promise<void> {
    try {
      await import('../screens/4-carousel-travel/carousel-travel.module');
      const resolver = await import('../screens/4-carousel-travel/carousel-travel.component');
      container.createComponent(resolver.CarouselTravelComponent);
    } catch (e) { env.local && console.warn('Error: TemplateInitializer: "carouselTravelView" module not found.', e); }
  }

  private async carouselActivitiesView(container: ViewContainerRef): Promise<void> {
    try {
      await promiseDelay(this.delay*1);
      await import('../screens/5-carousel-activities/carousel-activities.module');
      const resolver = await import('../screens/5-carousel-activities/carousel-activities.component');
      container.createComponent(resolver.CarouselActivitiesComponent);
    } catch (e) { env.local && console.warn('Error: TemplateInitializer: "carouselActivitiesView" module not found.', e); }
  }

  private async carouselLocationView(container: ViewContainerRef): Promise<void> {
    try {
      await promiseDelay(this.delay*2);
      await import('../screens/6-carousel-location/carousel-location.module');
      const resolver = await import('../screens/6-carousel-location/carousel-location.component');
      container.createComponent(resolver.CarouselLocationComponent);
    } catch (e) { env.local && console.warn('Error: TemplateInitializer: "carouselLocationView" module not found.', e); }
  }

  private async calendarView(container: ViewContainerRef): Promise<void> {
    try {
      await promiseDelay(this.delay*3);
      await import('../screens/7-calendar/calendar.module');
      const resolver = await import('../screens/7-calendar/calendar.component');
      container.createComponent(resolver.CalendarComponent);
    } catch (e) { env.local && console.warn('Error: TemplateInitializer: "calendarView" module not found.', e); }
  }

  private async clientInfoView(container: ViewContainerRef): Promise<void> {
    try {
      await promiseDelay(this.delay*4);
      await import('../screens/8-client-info/client-info.module');
      const resolver = await import('../screens/8-client-info/client-info.component');
      container.createComponent(resolver.ClientInfoComponent);
    } catch (e) { env.local && console.warn('Error: TemplateInitializer: "clientInfoView" module not found.', e); }
  }

  private async summaryView(container: ViewContainerRef): Promise<void> {
    try {
      await promiseDelay(this.delay*5);
      await import('../screens/9-summary/summary.module');
      const resolver = await import('../screens/9-summary/summary.component');
      container.createComponent(resolver.SummaryComponent);
    } catch (e) { env.local && console.warn('Error: TemplateInitializer: "summaryView" module not found.', e); }
  }

}
