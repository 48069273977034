import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { filter, map, startWith } from 'rxjs';

import { ExperienceFinderService } from './experience-finder.service';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';
import { CDNReaderService, SearchLocationService } from '@hiptraveler/common';

@Component({
  selector: 'experience-finder-screen',
  templateUrl: './experience-finder.component.html',
  styleUrls: ['./experience-finder.component.scss'],
  host: { class: 'experience-finder-screen' },
  viewProviders: [ ExperienceFinderService, CDNReaderService ],
  encapsulation: ViewEncapsulation.None
})
export class ExperienceFinderComponent implements OnInit {

  defaultImage?: string;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    public service: ExperienceFinderService,
    public state: ExperienceFinderStateService,
    private searchLocation: SearchLocationService,
    private cdnReader: CDNReaderService
  ) { }

  ngOnInit(): void {
    this.state.reset();
    this.service.dialogViewObserver();
    this.service.authenticatedObserver();
    this.cdnReader.getMultipleCdnData(
      'base64.appCommon', 'base64.experienceFinderDefault', 'base64.experienceFinderAssets'
    ).subscribe((data: any) => {
      this.defaultImage = data['response2']['value'];
    });

    this.searchLocation.searchLocationState$.subscribe((state: boolean) => {
      if (state) return;
      this.state.reset();
    });
    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map(() => this.searchLocation.rootSearchRoute),
      startWith(this.searchLocation.rootSearchRoute),
      filter(Boolean)
    ).subscribe(() => this.state.reset());
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape' && !this.dialog['_openDialogsAtThisLevel'].length) {
      this.state.overlayState$$.next(false);
    }
  }

}
