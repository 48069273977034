import { FormGroup } from '@angular/forms';
import * as moment from 'moment';

import { AddItineraryDto } from '@hiptraveler/data-access/api';
import { PlaceResult, brandCampaignId, clientVID, currentLang, isWidget, subdomain } from '@hiptraveler/common';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';

export function nonHT(): boolean {
  return subdomain() !== 'hiptraveler'
}

function formatDateToMMDDYYYY(date: Date): string {
  return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()}`;
}

function setItineraryName(numDays: number, location: string) {
  let itiName = '';

  if (numDays === 1) {
    itiName = 'One day in ';
  } else if (numDays % 7 === 0) {
    const num = numDays / 7;
    itiName = num > 1 ? `${num} weeks in ` : `${num} week in `;
  } else {
    itiName = `${numDays} days in `;
  }

  return itiName + location;
}

export function getRequestPayload({ form, placeResult, stateService, authenticated }: {
  form: FormGroup; placeResult: PlaceResult; stateService: ExperienceFinderStateService; authenticated: boolean;
}): AddItineraryDto {

  const value = { ...form.value };
  const numDaysSession = value.days?.split('-')?.[0];
  const numDays = numDaysSession?.includes('NaN') || !numDaysSession ? '1' : numDaysSession;
  value.startDate = value.startDate ? formatDateToMMDDYYYY(moment(value.startDate).toDate()) : '';
  value.endDate = value.endDate ? formatDateToMMDDYYYY(moment(value.endDate).toDate()) : '';

  const payload: AddItineraryDto = {
    name: setItineraryName(numDays, placeResult?.name!),
    lang: currentLang(),
    startDate: value.startDate,
    endDate: value.endDate,
    firstLoc: value.place,
    hasItinerary: value.itinerary === 'build',
    locMap: [{
      id: placeResult?.place_id!,
      formattedAddr: placeResult?.formatted_address!,
      location: placeResult?.name!,
      type: placeResult?.types?.[0] || 'locality'
    }],
    activities: stateService.getExperienceByCategory('activity'),
    experience: stateService.getExperienceByCategory('experience'),
    travelStyle: stateService.getExperienceByCategory('travelStyle'),
    numDays: +numDays,
    campgnReference: brandCampaignId(),
    customIti: value.itinerary,
    travelogue: false
  };

  payload.vId = clientVID();

  if (isWidget()) {
    payload.route = 'widget';
  }

  return payload;
}
