import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { BehaviorSubject, delay, distinctUntilChanged } from 'rxjs';

import { BrandState } from '@hiptraveler/data-access/brand';
import { ItineraryAction } from '@hiptraveler/data-access/itinerary';
import { LeafletMapControlStateService } from '@hiptraveler/features/leaflet-map';
import { DialogView, MainMenuView, TripFilterType } from '../experience-finder-screen/experience-finder.model';

@Injectable({
  providedIn: 'root'
})
export class ExperienceFinderStateService {

  overlayState$$ = new BehaviorSubject<boolean>(false);
  overlayState$ = this.overlayState$$.asObservable().pipe(delay(0));

  transparentBackdrop$$ = new BehaviorSubject<boolean>(false);
  transparentBackdrop$ = this.transparentBackdrop$$.asObservable().pipe(delay(0));
  
  selectedFilter$$ = new BehaviorSubject<TripFilterType>('experience');
  selectedFilter$ = this.selectedFilter$$.asObservable().pipe(delay(0));
  
  actionProcessing$$ = new BehaviorSubject<boolean>(false);
  actionProcessing$ = this.actionProcessing$$.asObservable();

  dialogView$$ = new BehaviorSubject<DialogView>('main-menu');
  dialogView$ = this.dialogView$$.asObservable();
  
  mainMenuView$$ = new BehaviorSubject<MainMenuView>('new_trip');

  selectedFilters$$ = new BehaviorSubject<string[]>([]);

  processing$$ = new BehaviorSubject<boolean>(false);
  
  overlayPositionState$$ = new BehaviorSubject<boolean>(true);
  overlayPositionState$ = this.overlayPositionState$$.asObservable().pipe(
    distinctUntilChanged(),
    delay(0)
  );
  
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private store: Store,
    private actions$: Actions,
    private leafletControl: LeafletMapControlStateService
  ) { }

  getExperienceByCategory(category: TripFilterType): string {
    const selectedFilters = this.selectedFilters$$.value;
    const experiences = this.store.selectSnapshot(BrandState.state)?.experiences || [];
    const filteredCodes = experiences.filter(e => e.category === category).map(e => e.code);
    return selectedFilters.filter((selected: string) => filteredCodes.includes(selected)).join(',');
  }

  submitDispatchListener(): void {
    this.actions$.pipe(
      ofActionDispatched(ItineraryAction.AddItinerary)
    ).subscribe(this.reset.bind(this));
  }

  reset(): void {
    this.dialogView$$.next('travel-style');
    this.mainMenuView$$.next('new_trip')
    this.selectedFilter$$.next('experience');
    this.leafletControl.activeSearchResultData$$.next(null);
    this.closePlacesAutocompleteDropdown();
    setTimeout(() => this.selectedFilters$$.next([]));
  }

  closePlacesAutocompleteDropdown(): void {
    if (isPlatformServer(this.platformId)) return;
    const pac: HTMLDivElement[] = Array.from(document.getElementsByClassName('pac-container')) as any;
    pac.forEach((element: HTMLDivElement) => {
      element.style.display = 'none';
    });
  }

}

// For testing only
// this.overlayState$$.next(true);
// this.mainMenuView$$.next('plan_a_trip')
// this.dialogView$$.next('plan-trip');
// this.dialogView$$.next('auth-menu');
// this.selectedFilters$$.next(["active","cultural_immersion","experiential","slow_travel","culture_history_tours","art_museum_tours"]);
// this.selectedFilters$$.next([ "bahamian_beaches", "bahamian_cultural_immersion", "b_experiential", "b_slow_travel", "art_museum_tours", "cultural_history_tours" ]);
