import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild, ViewEncapsulation } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { delay, filter, map, skip, takeUntil } from 'rxjs';
import { concat } from 'lodash';
import * as moment from 'moment';

import { FormEventService, FormFeatureService, FormSubmitService, viewProviders } from './view-providers';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';
import { SearchLocationData, SearchLocationService } from '@hiptraveler/common';
import { PlanTripService } from '../plan-trip.service';
import { getFiltersService, getFiltersStateService } from '../../search-filter-ref';
import { BrandState } from '@hiptraveler/data-access/brand';
import { Experience } from '@hiptraveler/data-access/api';
import { Store } from '@ngxs/store';
import { ListItem } from '../../../../modules/shell--search-page-root/filters/list-items';

@Component({
  selector: 'plan-trip-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  host: { class: 'experience-finder-screen-plan-trip-form' },
  encapsulation: ViewEncapsulation.None,
  viewProviders
})
export class FormComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('placeAutocomplete') placeAutocomplete: ElementRef<HTMLInputElement>;

  minDate = new Date().toISOString();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private store: Store,
    public formFeature: FormFeatureService,
    public formSubmit: FormSubmitService,
    public formEvent: FormEventService,
    private planTrip: PlanTripService,
    private searchLocation: SearchLocationService,
    private stateService: ExperienceFinderStateService
  ) { }

  ngOnInit(): void {
    this.planTrip.externalViewState$$.next(false);
    this.searchLocation.data && this.locationChanged(this.searchLocation.data);
    this.searchLocation.searchLocation$.subscribe(value => this.locationChanged(value));
    this.stateService.overlayState$.pipe(filter(Boolean)).subscribe(() => {

      // ---

      try {
        const { checkInDate, checkOutDate, activities, experiences, travelStyle } = Object.fromEntries(
          new URLSearchParams(window.location.search)
        );
        this.stateService.selectedFilters$$.next(concat(
          activities?.split(','), experiences?.split(','), travelStyle?.split(','),
        ));
        this.formFeature.form.patchValue({
          startDate: checkInDate ? moment(checkInDate, "MM-DD-YYYY") : undefined,
          endDate: checkOutDate ? moment(checkOutDate, "MM-DD-YYYY") : undefined,
        });
      } catch (error) { }

      // ---

      const brandExperiences = this.store.selectSnapshot(BrandState.experiences) || [];
      const activities = brandExperiences.map((experience: Partial<Experience>) => ({
        type: experience?.category === 'activity' ? 'activities' 
          : experience?.category === 'experience' ? 'exp' : 'travelStyle',
        name: experience?.name || '',
        value: experience?.code || ''
      })) as ListItem[];
      
      const filtersState = getFiltersStateService();

      this.stateService.selectedFilters$$.asObservable().pipe(
        takeUntil( this.stateService.overlayState$.pipe(map(e => !e), skip(1)) )
      ).subscribe((filters) => {
      
        filtersState.selectedItems$$
        filtersState.selectedTravelStyles$$

        const listItems = filters.map(filter => activities.find(e => e.value === filter));

        const selectedItems = listItems.filter(e => e?.type === 'activities') as ListItem[];
        const selectedTravelStyles = listItems.filter(e => e?.type !== 'activities') as ListItem[];

        const _selectedItems = filtersState.selectedItems.filter(e => e.type === 'activities');
        const _selectedTravelStyles = filtersState.selectedTravelStyles;

        if (_selectedItems.length !== selectedItems.length) {
          filtersState.selectedItems$$.next(selectedItems);
        }

        if (_selectedTravelStyles.length !== selectedTravelStyles.length) {
          filtersState.selectedTravelStyles$$.next(selectedTravelStyles);
        }
      });

      // ---

      const filters = getFiltersService();

      this.formFeature.form.valueChanges.pipe(
        takeUntil( this.stateService.overlayState$.pipe(map(e => !e), skip(1)) )
      ).subscribe(({ startDate, endDate }) => {
        filters.form.patchValue({ checkInDate: startDate, checkOutDate: endDate });
      });
    });
  }

  ngOnDestroy(): void {
    this.planTrip.externalViewState$$.next(false);
  }

  ngAfterViewInit(): void {

    if (isPlatformServer(this.platformId)) return;

    this.formEvent.initialize({ placeAutocomplete: this.placeAutocomplete.nativeElement });
    this.formFeature.syncDaysAndDateRangeInputFields();
  }

  locationChanged(value: SearchLocationData): void {
    const { locId: place_id, location: formatted_address, name, latitude, longitude } = value;
    const location: any = {
      lat: () => +(latitude || '0'),
      lng: () => +(longitude || '0')
    };
    this.formFeature.form.patchValue({ place: name });
    setTimeout(() => {
      this.placeAutocomplete.nativeElement.value = name || '';
      this.formFeature.placeResult = { place_id, formatted_address, name, geometry: { location }, types: [value?.type || ''] };
    }, 10);
  }
  
  submit(): void {
    this.formSubmit.submit({ placeResult: this.formFeature.placeResult });
  }

}
