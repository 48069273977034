import { ElementRef, Inject, Injectable, OnDestroy, PLATFORM_ID, ViewContainerRef } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, distinctUntilChanged, filter, firstValueFrom, map, skip, startWith, takeUntil, tap } from 'rxjs';

import { BrandCampaign } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { AppListenerService, currentLang, getPathname, isBrandIframeWidget, promiseDelay, SearchLocationService } from '@hiptraveler/common';
import { expFinderPanelAction, ExpFinderPanelAction } from '@hiptraveler/features/experience-finder';
import { ExperienceFinderStateService } from './experience-finder-state.service.z';

@Injectable()
export class ExperienceFinderAiService implements OnDestroy {

  @Select(BrandState.brandCampaign) brandCampaign$: Observable<Partial<BrandCampaign> | null>;

  experienceFinderRef: ViewContainerRef;
  panelAction: ExpFinderPanelAction;
  subscription$ = new Subject<void>();
  
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private element: ElementRef<HTMLElement>,
    private router: Router,
    private store: Store,
    private appListener: AppListenerService,
    private searchLocation: SearchLocationService,
    private stateService: ExperienceFinderStateService
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  initializeExperienceFinder(): void {
    this.panelAction = expFinderPanelAction();
  }

  async experienceFinderPanelDisplay(state: boolean = !this.stateService.overlayState$$.value): Promise<void> {
    
    const processState = this.stateService.processing$$;
    
    if (!isPlatformBrowser(this.platformId) || processState.value) return;
    processState.next(true);

    // Template is not available until brandCampaign data is present
    await firstValueFrom(this.store.select(BrandState.brandCampaign).pipe(filter(Boolean))); 

    if (!state) {
      this.experienceFinderDisplayState(state);
      await promiseDelay(300); // 300 ms delay after the animation ends
    }

    state
      ? this.panelAction?.openExperienceFinderPanel(this.experienceFinderRef, { desktop: true })
      : this.panelAction?.closeExperienceFinder();
      
    if (state) {
      await promiseDelay(0); // UX Delay for smoother animation transition
      this.experienceFinderDisplayState(state)
    }
  }

  observeExpFinderViewChanges(): void {

    this.appListener.clientWidth$.pipe(
      map(e => e < 700),
      distinctUntilChanged(),
      skip(1),
      takeUntil(this.subscription$)
    ).subscribe(async () => {
      if (!this.stateService.overlayState$$.value) return;
      await promiseDelay(100);
      this.experienceFinderPanelDisplay(false);
      await promiseDelay(400);
      this.experienceFinderPanelDisplay(true);
    });
  }

  observeExpFinderBubblePosition(): void {
    let previous: any = null;
    this.stateService.transparentBackdrop$.pipe(
      skip(1),
      takeUntil(this.subscription$)
    ).subscribe(async (state: boolean) => {
      if (this.stateService.isMobile) return;
      if (previous === null || !state) {
        previous = state;
        return;
      }
      await promiseDelay(300);
      previous = state;
      this.experienceFinderPanelDisplay(state);
    });
  }

  observeExpFinderHostParentWidth(): void {

    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map(e => e.url),
      startWith(this.router.url),
      map(e => e === `/${currentLang()}`),
      filter(Boolean),
      tap(() => {
        if (this.appListener.clientWidth$$.value > 1100) return
        this.stateService.overlayPositionState$$.next(true);
      })
    ).subscribe();

    this.appListener.clientWidth$.pipe(
      map(e => e < 1100),
      distinctUntilChanged(),
      skip(1),
      takeUntil(this.subscription$)
    ).subscribe((state: boolean) => {
      if (!this.searchLocation.rootSearchRoute) return;
      this.stateService.overlayPositionState$$.next(state);
    });
  }

  private experienceFinderDisplayState(state: boolean): void {
    
    const element = this.element.nativeElement.getElementsByClassName('experience-finder').item(0);
    const expFinderAI = element?.lastElementChild;
    if (!element) return;

    expFinderAI!.classList[state ? 'add' : 'remove']('active');

    this.stateService.overlayState$$.next(state);
    this.stateService.processing$$.next(false);
  }

  get iframeWidget$(): Observable<any> {
    return this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map(e => e.url),
      startWith(this.router.url),
      map(url => getPathname(url)),
      map(e => e === `/${currentLang()}` && isBrandIframeWidget())
    )
  }

}
