import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { combineLatest, filter, map, Observable, Subject, takeUntil } from 'rxjs';

import { AuthState } from '@hiptraveler/data-access/auth';
import { UserState, UserStateModel } from '@hiptraveler/data-access/user';
import { BrandState } from '@hiptraveler/data-access/brand';
import { AppListenerService, authenticatedProfilePlaceholder, currentLang } from '@hiptraveler/common';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { ImageSvgExternalService } from '@hiptraveler/data-access/external';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';

@Component({
  selector: 'bottom-navbar',
  templateUrl: './bottom-navbar.component.html',
  styleUrls: ['./bottom-navbar.component.scss'],
  host: { class: 'site-navigation-bottom-navbar-host' },
  viewProviders: [ AuthDialogActionService ],
  encapsulation: ViewEncapsulation.None
})
export class BottomNavbarComponent implements OnInit, OnDestroy {

  @Select(UserState.state) userState$: Observable<UserStateModel | null>;

  @Output() visibilityChanged = new EventEmitter<string>();
  subscription$ = new Subject<void>();
  displayState$ = this.imageSvg.svgCollection$.pipe(
    filter(value => !!value?.['navbar-c'])
  );

  authenticatedProfilePlaceholder: string = authenticatedProfilePlaceholder;

  constructor(
    private element: ElementRef<HTMLElement>,
    private router: Router,
    private store: Store,
    private appListener: AppListenerService,
    private authDialog: AuthDialogActionService,
    private imageSvg: ImageSvgExternalService,
    private stateService: ExperienceFinderStateService
  ) { }

  ngOnInit(): void {
    this.appListener.bottomNavbarVisibility$.pipe(takeUntil(this.subscription$)).subscribe((state: boolean) => {
      if (state) {
        this.element.nativeElement.style.display = 'flex';
        this.visibilityChanged.emit('block');
      } else {
        this.element.nativeElement.style.display = 'none';
        this.visibilityChanged.emit('none');
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  get authenticated$(): Observable<boolean> {
    return combineLatest([
      this.store.select(AuthState.authenticated),
      this.store.select(UserState.authenticated)
    ]).pipe( map(statuses => statuses.every(Boolean)) );
  }

  navigate(option: 'explore' | 'trips' | 'profile'): void {
    switch (option) {
      case 'explore': {
        const brandCampaign = this.store.selectSnapshot(BrandState.brandCampaign);
        const link = brandCampaign?.campaignCustom?.navHeaders?.find(e => e.category === 'All')?.link || 'search';
        this.router.navigateByUrl(`${currentLang()}/${link}`);
        break;
      }
      case 'trips': {
        break;
      }
      case 'profile': {
        if (!this.store.selectSnapshot(UserState.authenticated)) {
          this.openDialog();
          return;
        }
        const profile = this.store.selectSnapshot(UserState.state)!.uTitle!;
        this.router.navigateByUrl(`/${currentLang()}/profile/${profile}/about`);
        break;
      }
    }
  }

  openAddItineraryDialog(): void {
    this.stateService.overlayState$$.next(!this.stateService.overlayState$$.value);
  }

  private openDialog(): void {
    this.appListener.bottomNavbarVisibility$$.next(false)
    const ref: MatDialogRef<any, any> = this.authDialog.open('login') as any;
    ref.beforeClosed().subscribe(() => this.appListener.bottomNavbarVisibility$$.next(true));
  }

}
