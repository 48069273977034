import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { ClientInfoStateService } from './client-info-state.service';

@Component({
  selector: 'client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.scss'],
  host: { class: 'experience-finder-ai--host-client-info experience-finder-ai--host-element' },
  viewProviders: [ ClientInfoStateService ],
  encapsulation: ViewEncapsulation.None
})
export class ClientInfoComponent implements OnInit {

  constructor(
    public service: ClientInfoStateService
  ) { }

  ngOnInit(): void {
    this.service.clientDataObserver();
    this.service.formValueObserver();
    /** To do (expfinder-ai): Do not uncomment or remove */
    // this.completions.authInitialize();
    // this.chatAI.observe();
  }

}
