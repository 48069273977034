
<button mat-icon-button (click)="backToPreviousView()">
  <mat-icon svgIcon="experience-finder-screen-registration-menu.back-icon-svg"></mat-icon>
</button>

<h2 class="form--title" translate="auth.register"></h2>

<form class="form--field-content" [formGroup]="service.form">
  <div class="form--field-content-pane">

    <div class="form--field">
      <mat-form-field>
        <mat-label>{{ 'auth.first-name' | translate }}</mat-label>
        <input matInput type="text" name="firstName" formControlName="firstName">
        <mat-error *ngIf="controlError('firstName')">{{ 'auth.invalid-first-name' | translate }}</mat-error>
        <mat-error *ngIf="requiredControl('firstName')">{{ 'auth.first-name-required' | translate }}</mat-error>
      </mat-form-field>
    </div>
    
    <div class="form--field">
      <mat-form-field>
        <mat-label>{{ 'auth.email' | translate }}</mat-label>
        <input matInput type="text" autocomplete="username" name="email" formControlName="email">
        <mat-error *ngIf="controlError('email')">{{ 'auth.invalid-email-format' | translate }}</mat-error>
        <mat-error *ngIf="requiredControl('email')">{{ 'auth.email-required' | translate }}</mat-error>
      </mat-form-field>
    </div>
    
    <div class="form--field">
      <mat-form-field>
        <mat-label>{{ 'auth.location' | translate }}</mat-label>
        <input matInput type="text" name="location" locationFieldInput formControlName="userLoc" #searchLocation>
        <mat-error *ngIf="controlError('userLoc')">{{ 'auth.invalid-hometown-format' | translate }}</mat-error>
        <mat-error *ngIf="requiredControl('userLoc')">{{ 'auth.hometown-required' | translate }}</mat-error>
      </mat-form-field>
    </div>
  
  </div>
  <div class="form--field-content-pane">
    
    <div class="form--field">
      <mat-form-field>
        <mat-label>{{ 'auth.last-name' | translate }}</mat-label>
        <input matInput type="text" name="lastName" formControlName="lastName">
        <mat-error *ngIf="controlError('lastName')">{{ 'auth.invalid-last-name' | translate }}</mat-error>
        <mat-error *ngIf="requiredControl('lastName')">{{ 'auth.last-name-required' | translate }}</mat-error>
      </mat-form-field>
    </div>
  
    <div class="form--field">
      <mat-form-field>
        <mat-label>{{ 'auth.password' | translate }}</mat-label>
        <input matInput type="password" autocomplete="current-password" name="password" formControlName="password">
        <mat-error *ngIf="controlError('password')">{{ 'auth.invalid-password-format' | translate }}</mat-error>
        <mat-error *ngIf="requiredControl('password')">{{ 'auth.password-required' | translate }}</mat-error>
      </mat-form-field>
    </div>
    
    <div class="form--field checkbox" [ngClass]="{ checked: travelTradeCheckbox.checked }">
      <mat-checkbox #travelTradeCheckbox formControlName="travelTrade"></mat-checkbox>
      <p (click)="travelTradeCheckbox.toggle()" translate="auth.travel-trade"></p>
      <mat-form-field>
        <mat-label>{{ 'auth.travel-trade-option' | translate }}</mat-label>
        <mat-select #travelTradeSelect formControlName="travelTrade" panelClass="experience-finder-widget-register-form-travel-trade">
          <mat-option value="htAgent">{{ 'auth.travel-agent' | translate }} <mat-icon>done</mat-icon></mat-option>
          <mat-option value="htOperator">{{ 'auth.tour-operator' | translate }} <mat-icon>done</mat-icon></mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form--field checkbox" *ngIf="service.bahamas">
      <mat-checkbox #newsletterCheckbox formControlName="acceptNewsLetter"></mat-checkbox>
      <p (click)="newsletterCheckbox.toggle()" translate="auth.receive-updates"></p>
    </div>
  
  </div>
</form>

<p class="form--policy-info" *ngIf="service.hiptraveler">
  {{ 'auth.terms-agreement' | translate }} <span (click)="termsAndConditions.open()">{{ 'auth.terms-link' | translate }}</span> {{ 'auth.and' | translate }} <span (click)="privacyPolicy.open()">{{ 'auth.privacy-policy' | translate }}</span>
</p>

<p class="form--policy-info" *ngIf="!service.hiptraveler">
  {{ 'auth.account-registration' | translate: { value: service.brandTitle$ | async } }} <span (click)="termsAndConditions.open()">{{ 'auth.terms-link' | translate }}</span> {{ 'auth.and' | translate }} <span (click)="privacyPolicy.open()">{{ 'auth.privacy-policy' | translate }}</span>
</p>
